import timeLimit from 'time-limit-promise';
import { getCart, updateAttributes } from '../api/shared-app-requests';
import { parseJsonDeprecated } from '../api/utils';
import { AppStorage } from '../storage';
import { Offer, Product } from '../types';
import captureException from '../utils/sentry';
import { clearAssociatedProduct } from './cart';
import { clearDiscount } from './discounts';
import { getHandleForProductOrItemIdForService } from './offer-utils';

export const TRACKING_KEY = 'candyrack-tracking';

/*
 * Tech debt: Can be further split into different types for addToCart/view/removeFromCart
 */
type TrackingData = {
  event_type: string;
  cart_token: string | null;
  shop: string;
  data:
    | {}
    | {
        shopify_product_variant_id: number;
        parent_product: {
          id: number;
          handle: string;
        };
        quantity: number;
      };
  handle?: string;
  offer?: number;
  offers?: number[];
};

export default class Tracker {
  cartToken: string | null;
  shop: string;
  storage: AppStorage = new AppStorage('sessionStorage');

  constructor() {
    this.cartToken = this.storage.getItem(TRACKING_KEY);
    this.shop = window.Shopify.shop;
  }

  /*
   ** Returns the token from the cart, and sets it as a storage TRACKING_KEY, if not already set
   */
  async getCartToken(): Promise<string | null> {
    if (!this.cartToken) {
      const { token } = await getCart();
      this.cartToken = token;
      this.storage.setItem(TRACKING_KEY, token);
    }
    return this.cartToken;
  }

  async trackView(offers: Offer[]) {
    return this.track({
      event_type: 'view',
      cart_token: await this.getCartToken(),
      shop: this.shop,
      data: {},
      offers: offers.map((o) => o.id),
    });
  }

  async trackAddToCart(offer: Offer, parentProduct: Product) {
    await updateAttributes({
      candyrack_token: await this.getCartToken(),
    });

    let addToCartHandleData = getHandleForProductOrItemIdForService(offer);
    return this.track({
      event_type: 'add_to_cart',
      cart_token: await this.getCartToken(),
      shop: this.shop,
      offer: offer.id,
      handle: addToCartHandleData,
      data: {
        shopify_product_variant_id: offer.shopifyProductVariantIdComputedInFE,
        parent_product: {
          id: parentProduct.id,
          handle: parentProduct.handle,
        },
        quantity: offer.quantityComputedInFE || 1,
      },
    });
  }

  /*
   ** TODO: According to APPS-5551 Jira discussions we can remove this.
   ** We can do so in a separate, dedicated task.
   */
  async trackRemoveFromCart(offer: Offer) {
    return this.track({
      event_type: 'remove_from_cart',
      cart_token: await this.getCartToken(),
      shop: this.shop,
      offer: offer.id,
      data: {},
    });
  }

  /*
   ** We were tracking failed cleanUps for a period but it seems this happens
   ** quite often. We don't plan on doing something (at least for now) about it,
   ** as we don't have much info about the situation, no merchant complained about it and it seems to be mostly
   ** caused by the triggered fetch on local tests. Relevant discussion can be found in APPS-5744
   */
  async cleanUpCandyRackStorageTrackingInformation(): Promise<void> {
    if (window.Shopify.checkout) {
      const token = await this.getCartToken();
      if (token) {
        clearAssociatedProduct();
        clearDiscount();
        this.storage.removeItem(TRACKING_KEY);
      }
    }
  }

  track(data: TrackingData) {
    const hostname = process.env.REACT_APP_CUSTOMER_API_HOSTNAME;
    const fetchResult = parseJsonDeprecated(
      fetch(`${hostname}api/candyrack/track-offers`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify(data),
      }),
    ).catch((error) => captureException(error));

    return timeLimit(fetchResult, 500);
  }
}
