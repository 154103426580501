/*
 ** Relevant for CR (including checkout button)
 */
export const CANDYRACK_DISCOUNT_CODES_LOCAL_STORAGE_KEY =
  'candyrack-discount-code';

/*
 ** Used for CR and CandyCart.
 ** TODO: Potential for splitting this up and separate this for the apps
 */
export const CANDYRACK_DISCOUNTED_PRODUCTS_LOCAL_STORAGE_KEY =
  'candyrack-discounted-products';

/*
 ** Used in CandyRack and CC
 */
export const CANDYRACK_ASSOCIATED_PRODUCTS_LOCAL_STORAGE_KEY =
  'candyrack-associated-products';

/*
 ** Relevant for CR checkout button.
 */
export const CANDYRACK_CHECKOUT_BUTTON_DISCOUNT_CODE_LOCAL_STORAGE_KEY =
  'candycart-discount-code';

/*
 ** Relevant for CR checkout button
 */
export const CANDYRACK_CHECKOUT_BUTTON_DISCOUNTED_PRODUCT_LOCAL_STORAGE_KEY =
  'candycart-discounted-product';

/*
 ** Relevant for CR Add to Cart
 */
export const CANDYRACK_OFFSET_VALUE = 'candyrack-offset-value';

/*
 ** Temporal item, needed for Cypress tests during the
 ** transient period with one codebase - two environments.
 ** As soon as we have two environments and two codebases,
 ** we can get rid of this, so its no worth putting more time
 ** into a more sophisticated solution.
 */
export const IS_CANDYRACK_BASE_APP_FOR_TEST_PURPOSES =
  'is-candyrack-base-app-for-test-purposes';
