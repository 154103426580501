import { Offer } from '../types';

/*
 * In case an offer is added to the cart, we fill the shopifyProductHandleComputedInFE
 * as well as the shopifyProductVariantIdComputedInFE. However, for services the handle remains
 * empty. In this case the fallback to the relevant item_id takes place, when we need to send handle to the BE.
 */
export function getHandleForProductOrItemIdForService(offer: Offer) {
  let handleData = offer.shopifyProductHandleComputedInFE;
  if (!handleData) {
    // in case of service we use the item_id
    const foundItem = offer.items.find((item) =>
      item.item_variants.some(
        (itemVariant) =>
          itemVariant === offer.shopifyProductVariantIdComputedInFE,
      ),
    );
    if (foundItem) {
      handleData = foundItem.item_id.toString();
    }
  }
  return handleData;
}
