import { loadApp } from './utils/app-loaders';
import { getAppsInfo } from './api/shared-app-requests';
import initialize from './dom/CandyCart/initialize';
import { isMultipleDiscountsExist } from './utils/discounts';
import { REGISTERED_APPS } from './dissolve/types';

const initializePopup = async () => {
  const appsInfo = await getAppsInfo();
  loadApp(appsInfo);
};

const style = document.createElement('style');
style.textContent = `
 .candyrack-body-open {
  position: fixed !important;
  overflow-y: scroll!important;
  width: 100%!important;
 }
`;

document.head.appendChild(style);

document.addEventListener('candyrack-closed', () => {
  if (isMultipleDiscountsExist(REGISTERED_APPS.CANDYRACK)) {
    window.CandyCart = window.CandyCart
      ? { ...window.CandyCart, isLoading: true }
      : ({ isLoading: true } as any);
    initialize(false);
  }
});

if (
  document.readyState === 'complete' ||
  document.readyState === 'interactive'
) {
  initializePopup();
} else {
  document.addEventListener('DOMContentLoaded', initializePopup);
}
