import { changeItems, getCart } from '../api/shared-app-requests';
import storage from '../storage';
import { Cart, Offer } from '../types';
import { CANDYRACK_ASSOCIATED_PRODUCTS_LOCAL_STORAGE_KEY } from './constants';
import { REGISTERED_APPS } from '../dissolve/types';
import { logInfo } from './logging';
import { getDiscountedProductWithDiscount } from './discounts';

const ASSOCIATED_PRODUCTS_FOR_REMOVAL_KEY =
  'candyrack-associated-products-for-removal';
const TRANSLATIONS_KEY = 'candyrack-translations';

export function saveAssociatedProduct(
  offer: Offer,
  relatedProductHandle: string,
  parentProductVariantId: number | undefined,
) {
  if (!offer.shopifyProductVariantIdComputedInFE) {
    return;
  }
  storage.setItem(
    CANDYRACK_ASSOCIATED_PRODUCTS_LOCAL_STORAGE_KEY,
    JSON.stringify({
      ...getAssociatedProducts(),
      [offer.shopifyProductVariantIdComputedInFE]: relatedProductHandle,
    }),
  );
  if (offer.remove_upsell_product_when_parent_product_is_removed) {
    storage.setItem(
      ASSOCIATED_PRODUCTS_FOR_REMOVAL_KEY,
      JSON.stringify({
        ...getAssociatedProductsForRemoval(),
        [offer.shopifyProductVariantIdComputedInFE]: {
          handle: relatedProductHandle,
          quantity: offer.quantityComputedInFE,
          parentProductVariantId: parentProductVariantId,
        },
      }),
    );
  }
}

export function getAssociatedProducts() {
  const data = storage.getItem(CANDYRACK_ASSOCIATED_PRODUCTS_LOCAL_STORAGE_KEY);
  if (data) {
    try {
      return JSON.parse(data);
    } catch {
      return {};
    }
  }
  return {};
}

export function getAssociatedProductsForRemoval() {
  const data = storage.getItem(ASSOCIATED_PRODUCTS_FOR_REMOVAL_KEY);
  if (data) {
    try {
      return JSON.parse(data);
    } catch {
      return {};
    }
  }
  return {};
}

export function saveTranslations(data: any) {
  storage.setItem(TRANSLATIONS_KEY, JSON.stringify(data));
}

function getTranslations() {
  const data = storage.getItem(TRANSLATIONS_KEY) || '';
  let translations = {
    cart_discount_note: 'Discount AMOUNT will be applied at checkout.',
  };
  try {
    const parsedData = JSON.parse(data);
    if (parsedData.text?.cart_discount_note) {
      translations.cart_discount_note = parsedData.text.cart_discount_note;
    }
  } catch {}
  return translations;
}

export function showInfoInCart(
  discountedVariants: { discount_code: ''; covered_variant_ids: number[] }[],
) {
  const { cart_discount_note } = getTranslations();
  document
    .querySelectorAll<HTMLElement>('[candyrack-cart-info]')
    .forEach((info) => info.remove());

  discountedVariants.forEach((discountedVariant) => {
    discountedVariant.covered_variant_ids.forEach((variant) => {
      const discount = getDiscountedProductWithDiscount().find(
        (p: any) => p.variantId === variant,
      )?.discount;
      if (discount) {
        displayVariant(variant, cart_discount_note.replace('AMOUNT', discount));
      }
    });
  });
}

export function displayVariant(variantId: number, message: string) {
  const allElements = Array.from(
    document.querySelectorAll<HTMLAnchorElement>(`a[href$="${variantId}"]`),
  );

  let elements = allElements.filter(
    (e) => !e.firstElementChild || e.firstElementChild.tagName !== 'IMG',
  );

  if (elements.length === 0) {
    elements = allElements;
  }

  if (elements.length > 0) {
    logInfo(
      `Attaching discount message ${message} for variant ${variantId}`,
      REGISTERED_APPS.CANDYRACK,
    );
    elements[elements.length - 1].insertAdjacentHTML(
      'afterend',
      `<div candyrack-cart-info style="font-size: 12px;">${message}</div>`,
    );
  }
}

export function clearAssociatedProduct() {
  logInfo(
    `Clear associated storage products information`,
    REGISTERED_APPS.CANDYCART,
  );
  storage.removeItem(CANDYRACK_ASSOCIATED_PRODUCTS_LOCAL_STORAGE_KEY);
}

export async function loadCartAndCleanupAssociatedProductData(
  runningAppName: REGISTERED_APPS,
) {
  const cart = await getCart();
  cleanupAssociatedProductData(cart, runningAppName);
}

export function cleanupAssociatedProductData(
  cart: Cart,
  runningAppName: REGISTERED_APPS,
) {
  for (const variantId of Object.keys(getAssociatedProducts())) {
    if (!cart.items.find((item) => item.variant_id === Number(variantId))) {
      logInfo(
        `Variant ${variantId} not found. Removing association info...`,
        runningAppName,
      );
      const products = getAssociatedProducts();
      delete products[variantId];
      storage.setItem(
        CANDYRACK_ASSOCIATED_PRODUCTS_LOCAL_STORAGE_KEY,
        JSON.stringify(products),
      );
    }
  }
  removeUpsellProductWhenParentProductIsRemoved(cart, runningAppName);
}

function removeUpsellProductWhenParentProductIsRemoved(
  cart: any,
  runningAppName: REGISTERED_APPS,
) {
  if (window.CandyRack?.popupOpen) {
    logInfo(
      `not checking removeUpsellProductWhenParentProductIsRemoved because modal is open`,
      runningAppName,
    );
    return;
  }
  const products = getAssociatedProductsForRemoval();
  let productsToRemove: any = {};
  const productsToPreserve: any = {};
  for (const variantId of Object.keys(products)) {
    logInfo(`Checking variant ${variantId}`, runningAppName);
    const parentProduct = cart.items.find(
      (item: any) =>
        item.handle === products[variantId].handle &&
        item.variant_id === products[variantId].parentProductVariantId,
    );
    const upsellProduct = cart.items.find(
      (item: any) => item.variant_id === Number(variantId),
    );
    const upsellAndParentAreSame =
      parentProduct?.variant_id === Number(variantId);
    const isMeantForRemoval =
      upsellAndParentAreSame &&
      parentProduct?.quantity <= products[variantId].quantity;
    const sameVariantIdAndLine = parentProduct
      ? cart.items.filter(
          (value: any) => value.variant_id === parentProduct.variant_id,
        ).length === 1
      : true;
    if (
      (!parentProduct && upsellProduct) ||
      (isMeantForRemoval && sameVariantIdAndLine)
    ) {
      logInfo(
        `Parent ${products[variantId].handle} not found. Removing upsell product...`,
        runningAppName,
      );
      let finalQuantity = upsellProduct.quantity - products[variantId].quantity;
      finalQuantity = finalQuantity < 0 ? 0 : finalQuantity;
      productsToRemove[upsellProduct.variant_id] = finalQuantity;
    } else {
      productsToPreserve[variantId] = products[variantId];
    }
  }
  storage.setItem(
    ASSOCIATED_PRODUCTS_FOR_REMOVAL_KEY,
    JSON.stringify(productsToPreserve),
  );
  if (Object.keys(productsToRemove).length > 0) {
    Promise.resolve(changeItems(productsToRemove)).then(() =>
      window.location.reload(),
    );
  }
}

export {
  ASSOCIATED_PRODUCTS_FOR_REMOVAL_KEY,
  CANDYRACK_ASSOCIATED_PRODUCTS_LOCAL_STORAGE_KEY,
};
