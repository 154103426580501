import { REGISTERED_APPS } from '../dissolve/types';
import storage from '../storage';
const LOCALSTORAGE_KEY = 'candyrack-debug';
let loggingEnabled = true;

try {
  loggingEnabled = !!storage.getItem(LOCALSTORAGE_KEY);
} catch {}

window.candyrackEnableDebug = () => {
  storage.setItem(LOCALSTORAGE_KEY, String(true));
  loggingEnabled = true;
  logInfo('Enabled console output 🔧', REGISTERED_APPS.CANDYRACK);
};

function getAppPrefix(app?: REGISTERED_APPS): string {
  return app ? `${app}: ` : 'Could not determine app: ';
}

function logError(message: any, app?: REGISTERED_APPS, ...rest: any[]) {
  const errorMessage =
    typeof message === 'string' ? getAppPrefix(app) + message : message;
  console.error(errorMessage, ...rest);
}

function logInfo(message: any, app?: REGISTERED_APPS, ...rest: any[]) {
  if (!loggingEnabled) {
    return;
  }
  const prefixedMessage =
    typeof message === 'string' ? getAppPrefix(app) + message : message;
  console.info(prefixedMessage, ...rest);
}

export { logError, logInfo };
