import { REGISTERED_APPS } from '../../dissolve/types';
import { logInfo } from '../../utils/logging';
import reportChunkError from '../../dissolve/services/chunkErrorMonitoring';

export default function initialize(
  defaultInit: boolean = true,
  storefrontAccessToken: string = '',
) {
  if (!window.CandyCart.isLoading) {
    return;
  }

  if (document.getElementById('candycart-root')) {
    logInfo('root is already present.', REGISTERED_APPS.CANDYCART);
    return;
  }

  import(/* webpackPreload: true */ './initPopup')
    .then(({ default: init }) => init(defaultInit, storefrontAccessToken))
    .catch(() =>
      reportChunkError(REGISTERED_APPS.CANDYCART, window.Shopify.shop),
    );
}
