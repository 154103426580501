import { REGISTERED_APPS } from '../dissolve/types';
import { logInfo } from './logging';

interface ErrorQueueEntry {
  error: Error;
  errorInfo: any;
}

const errorQueue: ErrorQueueEntry[] = [];

let sendErrorToSentry = (
  error: Error | unknown,
  app?: REGISTERED_APPS,
  errorInfo?: any,
) => {
  if (errorQueue.length === 0) {
    loadSentry(app);
  }
  if (error instanceof Error) {
    errorQueue.push({ error, errorInfo });
  } else {
    /*
     ** According to the Sentry logs it seems that somewhere an object of form {message: '', name: ''} is thrown.
     ** Also, according to our types its possible we have an unknown.
     ** We wrap this everything other than error here so we can evaluate and find the place of origin.
     */
    try {
      errorQueue.push({
        error: new Error(
          `Unknown error, please recheck creation: ${JSON.stringify(error)}`,
        ),
        errorInfo: errorInfo,
      });
    } catch (error) {
      errorQueue.push({
        error: new Error('Unknown error, please recheck creation'),
        errorInfo: errorInfo,
      });
    }
  }
};

export default sendErrorToSentry;

async function loadSentry(app?: REGISTERED_APPS) {
  logInfo('Encountered error. Loading Sentry...', app);
  const { init, withScope, captureException } = await import('@sentry/browser');

  init({
    dsn: process.env.REACT_APP_SENTRY_FE_CLIENT_DSN,
    integrations: (integrations) => {
      return integrations
        .filter(
          (integration) =>
            integration.name !== 'ReportingObserver' &&
            integration.name !== 'TryCatch' &&
            integration.name !== 'GlobalHandlers',
        )
        .map((integration) => {
          if (integration.name === 'InboundFilters') {
            //@ts-ignore
            integration.ignoreErrors = [
              'Failed to fetch',
              'SecurityError',
              'localStorage',
              'fetch',
              'The request timed out.',
              'InvalidStateError',
              'Access is denied.',
              'The operation couldn’t be completed',
              'NetworkError',
              'ChunkLoadError',
            ];
          }
          return integration;
        });
    },
  });

  sendErrorToSentry = (error, app, errorInfo) => {
    if (errorInfo) {
      withScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key]);
        });
      });
    }
    captureException(error);
  };

  logInfo('Sentry loaded', app);

  errorQueue.forEach(({ error, errorInfo }) => {
    logInfo('Sending error', app, error);
    sendErrorToSentry(error, errorInfo);
  });
}
