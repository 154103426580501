import {
  APIError,
  isAPIError,
  isNetworkError,
  NetworkError,
} from '../api/types';
import { AppsInfo as AppsInfoAPIResponse } from '../types';
import captureException from './sentry';
import { logError } from './logging';
import { REGISTERED_APPS } from '../dissolve/types';
import chunkErrorMonitoring from '../dissolve/services/chunkErrorMonitoring';
import {
  canLoadCandyRack,
  clearCandyrackCheckoutButtonDiscountInfoFromStorage,
  shouldAddCurrencyFunction,
  shouldLoadCandyCart,
  shouldLoadCandyRack,
  shouldLoadLastUpsell,
} from './app-utils';
import { fillWindowWithCurrencyFunction } from './currency-loader';
import Tracker from './tracking';

/**
 * @description flow:
 * 1. check if app is on correct url
 * 2. if so -> app can be loaded (step 4), if not -> check further (step 3)
 * 3. check for buttons on page (CR / CC / LU)
 * 4. load app
 */

export const loadApp = (res: AppsInfoAPIResponse | APIError | NetworkError) => {
  if (isAPIError(res)) {
    logError(res as APIError);
    captureException({
      name: 'APIError: installation check',
      message: (res as APIError).error_code,
    });
    clearCandyrackCheckoutButtonDiscountInfoFromStorage();
  } else if (isNetworkError(res)) {
    logError(res as NetworkError);
    clearCandyrackCheckoutButtonDiscountInfoFromStorage();
  } else {
    handleRes(res);
  }
};

const handleRes = (res: AppsInfoAPIResponse) => {
  /*
   ** Needs to be executed first, before our custom variables, as those custom variables are set in the config file itself.
   ** For cases as some landing pages, that are selling something, that are not technically a product page,
   ** we have the getButtons() function, which also allows us to load CR if we see any buttons that look like ATC buttons.
   ** We can adjust those in the config, so the config needs to be executed prior to calling the
   ** canLoadCandyRack function.
   */
  if (res?.candyrack?.is_installed && !window.CANDYRACK_CONFIG_EXECUTED) {
    const config = res.candyrack.config?.trim();
    if (config) {
      try {
        window.CANDYRACK_CONFIG_EXECUTED = true;
        window.eval(config);
      } catch (e) {
        logError('Config file contains an error', REGISTERED_APPS.CANDYRACK);
      }
    }
  }

  const crLoadRes = canLoadCandyRack();
  if (shouldAddCurrencyFunction(res, crLoadRes)) {
    /*
     ** Filling the window object with the Currency function
     ** and cleaning up the tracker.
     **
     ** The intention was to leave this as in previous versions.
     ** During the fork extraction we didn't want to touch too much
     ** of the functionality per-se. However, personally I can't follow the
     ** dependency on cleaning up storage information inside the Tracker.
     ** I propose to refactor it at some point, but
     ** due to a high intertwining I'd avoid this at this step.
     */
    fillWindowWithCurrencyFunction().catch((error) => captureException(error));
  }

  if (shouldLoadCandyRack(res, crLoadRes)) {
    const tracker = new Tracker();
    tracker.cleanUpCandyRackStorageTrackingInformation().catch(() => {
      // intentionally left blank to ignore the error
    });
    window.CandyRack = window.CandyRack
      ? { ...window.CandyRack, isLoading: true }
      : ({ isLoading: true } as any);
    importCandyRackLazy(
      crLoadRes.shouldRender,
      tracker,
      res.candyrack.storefront_access_token,
    );
  }

  if (shouldLoadCandyCart(res)) {
    window.CandyCart = window.CandyCart
      ? { ...window.CandyCart, isLoading: true }
      : ({ isLoading: true } as any);
    importCandyCartLazy(res.candyrack.storefront_access_token);
  }

  if (shouldLoadLastUpsell(res)) {
    // fortification
    if (res.candyrack?.is_installed) {
      const tracker = new Tracker();
      tracker.cleanUpCandyRackStorageTrackingInformation().catch(() => {
        // intentionally left blank to ignore the error
      });
    }
    window.LastUpsell = window.LastUpsell
      ? { ...window.LastUpsell, isLoading: true }
      : ({ isLoading: true } as any);
    importLastUpsellLazy(res.candyrack.storefront_access_token);
  }
};

const importCandyRackLazy = (
  shouldRender: boolean,
  tracker: Tracker,
  storefrontAccessToken: string,
): void => {
  import(/* webpackPreload: true */ '../dom/CandyRack/initialize')
    .then(({ default: initialize }) =>
      initialize(shouldRender, tracker, storefrontAccessToken),
    )
    .catch(() => {
      chunkErrorMonitoring(REGISTERED_APPS.CANDYRACK, window.Shopify.shop);
    });
};

const importCandyCartLazy = (storefrontAccessToken: string): void => {
  import(/* webpackPreload: true */ '../dom/CandyCart/initialize')
    .then(({ default: initialize }) => initialize(true, storefrontAccessToken))
    .catch(() => {
      chunkErrorMonitoring(REGISTERED_APPS.CANDYCART, window.Shopify.shop);
    });
};

const importLastUpsellLazy = (storefrontAccessToken: string): void => {
  import(/* webpackPreload: true */ '../dom/LastUpsell/initialize')
    .then(({ default: initializePPU }) => initializePPU(storefrontAccessToken))
    .catch(() => {
      chunkErrorMonitoring(REGISTERED_APPS.LAST_UPSELL, window.Shopify.shop);
    });
};
